import React, { Fragment } from 'react';
import './HomeGames.css';
import AdComponent from '../AdComponent/AdComponent';

function HomeGames() {
    let data = [
        {
            "id": 2,
            "title": "2048",
            "shortUrl": "2048",
            "logo": "2048.webp",
            "banner": "2048.webp",
        },
        {
            "id": 6,
            "title": "Fruit Ninja",
            "shortUrl": "fruit-ninja",
            "logo": "Fruit-Ninja.webp",
            "banner": "Fruit-Ninja.webp",
        },
        {
            "id": 9,
            "title": "Subway Surfers",
            "shortUrl": "subway-surfers-ultimate-fun",
            "logo": "Subway-Surfers-World-Tour-Las-Vegas.webp",
            "banner": "Subway-Surfers-World-Tour-Las-Vegas.webp",
        },
        {
            "id": 10,
            "title": "Brain Test",
            "shortUrl": "brain-test-tricky-puzzles",
            "logo": "Brain-Test-Tricky-Puzzles.webp",
            "banner": "Brain-Test-Tricky-Puzzles.webp",
        },
        {
            "id": 11,
            "title": "Stickman Hook",
            "shortUrl": "stickman-hook",
            "logo": "Stickman-Hook.webp",
            "banner": "Stickman-Hook.webp",
        },
        {
            "id": 16,
            "title": "Monster Tracks",
            "shortUrl": "monster-tracks",
            "logo": "Monster-Tracks.webp",
            "banner": "Monster-Tracks.webp",
        },
        // {
        //     "id": 19,
        //     "title": "Blumgi Rocket",
        //     "shortUrl": "blumgi-rocket",
        //     "logo": "Blumgi-Rocket.webp",
        //     "banner": "Blumgi-Rocket.webp",
        // },
        // {
        //     "id": 20,
        //     "title": "Blumgi Slime",
        //     "shortUrl": "blumgi-slime",
        //     "logo": "Blumgi-Slime.webp",
        //     "banner": "Blumgi-Slime.webp",
        // },
        {
            "id": 22,
            "title": "Runner",
            "shortUrl": "runner",
            "logo": "Runner.webp",
            "banner": "Runner.webp",
        },
        {
            "id": 21,
            "title": "Archery World",
            "shortUrl": "archery-world-tour",
            "logo": "Archery-World-Tour.webp",
            "banner": "Archery-World-Tour.webp",
        }
        // {
        //     "id": 28,
        //     "title": "supernova",
        //     "shortUrl": "supernova", 
        //     "logo": "supernova.webp",
        //     "banner": "supernova.webp",
        // }
    ]


    return (
        <>
            <div className='row m-0 g-3'>
                {
                    data.map((game, index) => {
                        if (index == 4) {

                        }
                        return <Fragment key={index}>
                            {index == 4 &&
                                <div className='col-12'>
                                    {/* <AdComponent
                                        client="ca-pub-3550658214779566"
                                        slot="5089325204"
                                        format="auto"
                                        style={{ display: 'block', width: '100%', height: '280px' }}
                                    /> */}
                                    <AdComponent
                                        client="ca-pub-3550658214779566"
                                        slot="9545591255"
                                        format="autorelaxed"
                                        style={{ display: 'block', width: '100%', height: 'auto' }}
                                    />
                                </div>
                            }
                            <div className="col-6">
                                <a href={`https://www.multigamezone.com/games/${game.shortUrl}`} className='link-underline-hide' >
                                    <div className="card gameCard h-100">
                                        <img src={require(`../../assets/images/${game.logo}`)} alt={"Multi Game Zone - " + data.title} />
                                        <div className="card-body p-0 p-1 text-center">
                                            <p className='m-0 title'>{game.title}</p>
                                        </div>
                                        <div className="card-footer">
                                            <button>PLAY NOW</button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Fragment>
                    })
                }
            </div>
        </>
    )
}

export default HomeGames