import React, { useEffect, useState } from 'react';
import './Home.css';
import Header from '../../Components/Header/Header';
import HomeGames from '../../Components/HomeGames/HomeGames';
import Footer from '../../Components/Footer/Footer';
import AboutSection from '../../Components/AboutSection/AboutSection';
import { Adsense } from '@ctrl/react-adsense';
import AdComponent from '../../Components/AdComponent/AdComponent';

function Home() {

    const [showPopUp, setShowPopUp] = useState(true);
    const [adAvailable, setAdAvailable] = useState(false); // To track ad availability

    // Function to check if the ad is available
    const checkAdAvailability = () => {
        // Logic to check if ad is available. For now, we can simulate the availability.
        // You can replace this with your actual ad-checking logic.
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true); // Simulate ad availability (true/false)
            }, 1000);
        });
    };

    useEffect(() => {
        // Set a 3-second delay before checking ad availability
        const timer = setTimeout(async () => {
            const isAdAvailable = await checkAdAvailability();
            if (isAdAvailable) {
                setAdAvailable(true);
                setShowPopUp(true); // Show the popup if the ad is available
            }
        }, 2000); // 3 seconds delay

        // return () => clearTimeout(timer); // Clean up timer on component unmount
    }, []); // Run once when component mounts

    console.log("av", adAvailable);

    return (
        <>
            {
                showPopUp && adAvailable && (
                    <div class="popup-overlay" id="popupOverlay" style={{ display: "flex" }}>
                        <div class="popup-content">
                            <button class="close-popup" onClick={() => setShowPopUp(false)}>x</button>
                            <div style={{ textAlign: "center" }}>
                                <AdComponent
                                    client="ca-pub-3550658214779566"
                                    slot="1413804079"
                                    format="auto"
                                    style={{ display: 'block', width: '100%', height: '280px' }}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
            {/* {
                showPopUp && <div class="popup-overlay" id="popupOverlay" style={{ display: "flex" }}>
                    <div class="popup-content">
                        <button class="close-popup" onClick={() => { setshowPopUp(false) }} >x</button>
                        <div style={{ textAlign: "center" }}>
                            {<AdComponent
                                client="ca-pub-3550658214779566"
                                slot="1413804079"
                                format="auto"
                                style={{ display: 'block', width: '100%', height: '280px' }}
                            />}
                        </div>
                    </div>
                </div>
            } */}

            <div className='Main_Bg'>
                <div className='content-section'>
                    <Header />
                    <div className='p-2'>
                        <AdComponent
                            client="ca-pub-3550658214779566"
                            slot="2918457438"
                            format="auto"
                            style={{ display: 'block', width: '100%', height: '280px' }}
                        />
                        <div className='px-2'>
                            <div className='custom-card mt-4'>
                                <div className='custom-heading'>Play free Multi GameZone games</div>
                                <a href="https://www.multigamezone.com/" ><button className='mt-2 mb-2 custom-button'>Let's Play</button></a>
                            </div>
                            {/* <div className='custom-card mt-4 py-4'>
                                <div className='custom-heading h4'>Prefer games which you want to play</div>
                                <a href="https://www.multigamezone.com/" ><button className='mt-4 custom-button p-2 px-4'>Car Games</button></a>
                                <a href="https://www.multigamezone.com/" ><button className='mt-4 ms-2 custom-button p-2 px-4'>Puzzle Games</button></a>
                            </div> */}
                        </div>
                        <div className='pt-2'>
                            <HomeGames />
                        </div>
                        <div className='px-2 my-4'>
                            <a href="https://www.multigamezone.com/" ><button className='custom-button w-100'>More Games</button></a>
                        </div>
                        <AboutSection />
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Home